.chat-container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
  }
  
  .messages {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  
  .message {
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    max-width: 80%;
  }
  
  .message.user {
    background-color: #dcf8c6;
    align-self: flex-end;
  }
  
  .message.ai {
    background-color: #f1f1f1;
    align-self: flex-start;
  }
  
  .input-container {
    display: flex;
  }
  
  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px 0 0 8px;
  }
  
  button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  